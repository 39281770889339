<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Kategoriebaum
                        </div>
                    </div>

                    <div class="card-header">
                        <Tree :value="categories" @change="saveOrder" foldAllAfterMounted>
                            <!-- eslint-disable-next-line -->
                            <span slot-scope="{node, index, path, tree}">
                                <i class="fas fa-folder-plus" @click="tree.toggleFold(node, path)" v-if="node.$folded"></i>
                                <i class="fas fa-folder-minus" @click="tree.toggleFold(node, path)" v-if="!node.$folded"></i>
                                <a class="ml-1" href="#">{{node.name}}</a>
                            </span>
                        </Tree>
                    </div>

                    <div class="card-footer">

                    </div>
                </div>
            </div>
        </div>
    </b-container>

</template>

<script>
import 'he-tree-vue/dist/he-tree-vue.css'
import {Tree, Fold, Draggable} from 'he-tree-vue'

export default {
    components: {Tree: Tree.mixPlugins([Fold, Draggable])},
    data() {
        return {
            categories: [],
        }
    },

    methods: {
        saveOrder() {
            this.$swal({
                    title: "Möchtest du die Reihenfolge der Kategorien wirklich ändern?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        console.log("Press Okay")
                        this.axios
                            .post("/categories/saveOrder", {data: this.categories} )
                            .then(() => {
                                this.loadCategories();
                                this.$Progress.finish();
                                this.$swal({
                                    icon: "success",
                                    title: "Reihenfolge geändert",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                                this.$Progress.fail();
                            });
                    }
                    if (!result.isConfirmed) {
                        console.log("Press Cance");
                        this.loadCategories();
                    }
                });
        },

        loadCategories() {
            this.$Progress.start();
            this.axios
                .get("/categories")
                .then((response) => {
                    this.categories = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created() {
        this.loadCategories();
        
    },

    mounted(){
        
    }
}
</script>

<style>

</style>